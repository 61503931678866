import React, { useMemo, useCallback, memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../store/store";
import { ChevronDown, ChevronRight } from 'lucide-react';


import {
  setColumns,
  setData,
  setGroupIndex,
  setSearchInput,
  setSearchResults,
  setChangedTradeCells,
  setShowAnimation,
  setModalOpen,
  setIsHovering,
  setColumnLayout,
  addGroup,
  swapColumns,
  addTickerToGroup,
  clearChangedCells,
  setChangedMarketDataCells,
  setSortColumn,
  sortData,
  initializeSortLayout,
} from "../store/slices/dataTableSlice";
import CustomPopUpModal from "./custom-popup-modal";
import GroupCreationForm from "./group-creation-form";
import { Fab, SpeedDial, SpeedDialAction, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { MoreVertRounded, SaveRounded } from "@mui/icons-material";
import { FinxaiTrade } from "../models/finxaiTrade";
import { FinxaiDataGroup } from "../models/data-group";
import { FinxaiMktData } from "../models/finxaiMktData";
import {
  setGroups,
  sortMarketData,
  rearrangeTradeOrders,
  sortTradeOrders,
  initializeDataSort,
} from "../store/slices/dataSlice";
import CustomSpeedDial from "./custom-speed-dial";
import { getCache } from "../utils/cache";
import QuantityCell from "./quantity-cell";
import { getWebSocketInstance } from "../store/slices/userSlice";
import { OrderStatus } from "../models/protobuff-objects/finxai_web_pb";
import logger from "../utils/logger";

export interface Column {
  name: string;
  selector: (row: any) => React.ReactNode;
  conditionalCellStyles?: {
    when: (row: any) => boolean;
    style: React.CSSProperties;
  }[];
}


export interface TableGroups {
  [groupName: string]: {
    [columnName: string]: string[];
  };
}


interface Props {
  onRowTap?: (rowData: any) => void;
  columns: Column[];
  data: any[];
  enableGrouping?: boolean;
  enableTableGrouping?: boolean;
  username?: string;
  uid?: string; /// Used for creation of groups based on this Uid
  /// eg: for trade tables it is "order_id" and for mkt_data table it is "security_id"
  tableGroups?: TableGroups;
  preExpandedGroups?: string[];
  allTableGroupsPreExpanded?: boolean;
  excludeData?: { [columnName: string]: string[] };
}

const CustomDataTable: React.FC<Props> = ({
  columns,
  data,
  username,
  enableGrouping,
  enableTableGrouping,
  uid,
  onRowTap,
  tableGroups,
  preExpandedGroups,
  excludeData,
  allTableGroupsPreExpanded
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    columnsState,
    dataState,
    isHovering,
    sortDirection,
    modalOpen,
    currentColumnLayout,
    groupIndex,
    groups,
    searchInput,
    searchResults,
    changedTradeCells,
    changedMarketDataCells,
    newDataIds,
    sortColIndex,
  } = useSelector((state: RootState) => state.dataTable);

  const { tradeOrders, marketData } = useSelector(
    (state: RootState) => state.data
  );
  const { currentTableIndex } = useSelector((state: RootState) => state.ui);
  const prevDataState = useRef<FinxaiTrade[]>([]);
  const currIndex = useRef<number>(0);
  const nIndex = useRef<number>(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRow, setSelectedRow] = useState<number | null>(null);


  const [expandedGroups, setExpandedGroups] = useState<string[]>(() => {
    if (allTableGroupsPreExpanded) {
      logger.info("Pre expanded data:", Object.keys(tableGroups ?? {}) )
      return Object.keys(tableGroups ?? {});
    }
    return preExpandedGroups ?? [];
  });
  const [groupedData, setGroupedData] = useState<{ [key: string]: any[] }>({});
  const [ungroupedData, setUngroupedData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);



  // Function to filter out excluded data
  const filterExcludedData = (data: any[]) => {
    return data.filter(row => {
      for (const [columnName, excludedValues] of Object.entries(excludeData ?? [])) {
        const column = columnsState.find(col => col.name === columnName);
        if (column) {
          const cellValue = column.selector(row)?.toString();
          if (cellValue && excludedValues.includes(cellValue)) {
            return false;
          }
        }
      }
      return true;
    });
  };
  const toggleGroup = (groupName: string) => {
    setExpandedGroups(prev => 
      prev.includes(groupName) 
        ? prev.filter(name => name !== groupName)
        : [...prev, groupName]
    );
  };


  const handleRowTap = (row: any, rowIndex: number) => {
    const isSelected = selectedRow === rowIndex;
    setSelectedRow(isSelected ? null : rowIndex);
    if (onRowTap && !isSelected) {
      onRowTap(row);
    }
  };
  function isFinxaiMktData(item: any): item is FinxaiMktData {
    return (item as FinxaiMktData).last_traded_px !== undefined;
  }

  function isFinxaiTrade(item: any): item is FinxaiTrade {
    return (item as FinxaiTrade).order_id !== undefined;
  }


/// Groups processing
useEffect(() => {
  setIsLoading(true);
  
  if (tableGroups && enableTableGrouping) {
    logger.info("Received table groups:", tableGroups )
    const filteredData = filterExcludedData(dataState);
    const newGroupedData: { [key: string]: any[] } = {};
    const remainingData: any[] = [];

    // Initialize all groups
    Object.keys(tableGroups).forEach(groupName => {
      newGroupedData[groupName] = [];
    });

    // Process each row
    filteredData.forEach(row => {
      let assigned = false;

      // Check each group
      Object.entries(tableGroups).forEach(([groupName, columnConfigs]) => {
        // Check each column configuration in the group
        Object.entries(columnConfigs).forEach(([columnName, values]) => {
          const column = columnsState.find(col => col.name === columnName);
          if (column) {
            const cellValue = column.selector(row)?.toString();
            if (cellValue && values.includes(cellValue)) {
              newGroupedData[groupName].push(row);
              assigned = true;
            }
          }
        });
      });

      if (!assigned) {
        remainingData.push(row);
      }
    });

    setGroupedData(newGroupedData);
   
  }
  
  setIsLoading(false);
}, [tableGroups, enableGrouping, dataState, columnsState, excludeData]);



  useEffect(() => {
    /// Table layout cache check
    const cacheKey = `${username}_tableLayout_${currentTableIndex}`;
    const cachedColumnsLayout = getCache(cacheKey) as Column[] | null;

    let columnsToUse: Column[];

    if (currentColumnLayout[currentTableIndex]) {
      logger.debug("Rearranged columns exist in memory for", currentTableIndex);
      columnsToUse = currentColumnLayout[currentTableIndex];
    } else if (cachedColumnsLayout) {
      logger.debug("Rearranged columns exist in cache:", cachedColumnsLayout);
      // Merge cached layout with the original columns to restore selectors
      columnsToUse = cachedColumnsLayout.map((cachedColumn: Column) => {
        const originalColumn = columns.find(
          (col) => col.name === cachedColumn.name
        );
        return {
          ...cachedColumn,
          selector: originalColumn?.selector || cachedColumn.selector,
          conditionalCellStyles:
            originalColumn?.conditionalCellStyles ||
            cachedColumn.conditionalCellStyles,
        };
      });
      logger.debug(
        "Setting cached column layout with selectors:",
        columnsToUse
      );
    } else {
      // Default column order if no cache found
      columnsToUse = columns;
    }

    // Set the columns in the state
    dispatch(setColumns(columnsToUse));

    // Set the rearranged data
    dispatch(setData(data));

    dispatch(
      initializeSortLayout({
        username: username!,
        tableIndex: currentTableIndex,
      })
    );
    /// Get cached index
    const cacheColumnNameKey = `${username}_sortLayoutColumnName_${currentTableIndex}`;

    // if (sortColIndex != null) {
    //   logger.info(
    //     "Setting internal data sort with cached index: ",
    //     sortColIndex
    //   );

    //   const field = getSelectorField(columnsState[sortColIndex].selector);

    //   dispatch(initializeDataSort({ field: field!, order: sortDirection }));
    // }
  }, [
    currentTableIndex,
    username,
    columns,
    currentColumnLayout,
    dispatch,
    data,
  ]);

  /// Changed value cells
  useEffect(() => {
    const changedTradeEntries: { [key: string]: Set<string> } = {};
    const changedMarketEntries: { [key: string]: Set<string> } = {};

    dataState.forEach((newData) => {
      const prevData = prevDataState.current.find((item) => {
        if (isFinxaiMktData(item)) {
          return item.security_id === newData.security_id;
        } else if (isFinxaiTrade(item)) {
          return item.order_id === newData.order_id;
        }
        return false;
      });

      if (prevData) {
        const changedFields = new Set<string>();
        Object.keys(newData).forEach((key) => {
          if (newData[key] !== prevData[key]) {
            changedFields.add(key);
          }
        });

        if (changedFields.size > 0) {
          if (isFinxaiMktData(newData)) {
            changedMarketEntries[newData.security_id] = changedFields;
          } else if (isFinxaiTrade(newData)) {
            changedTradeEntries[newData.order_id] = changedFields;
          }
        }
      }
    });

    if (Object.keys(changedTradeEntries).length > 0) {
      logger.debug("Updating trade entries: Component view");
      dispatch(setChangedTradeCells(changedTradeEntries));
    }

    if (Object.keys(changedMarketEntries).length > 0) {
      logger.debug("Updating Market Data entries: Component view");

      dispatch(setChangedMarketDataCells(changedMarketEntries));
    }

    const timer = setTimeout(() => {
      dispatch(setShowAnimation(false));
      dispatch(clearChangedCells());
    }, 3000);

    prevDataState.current = dataState;

    return () => clearTimeout(timer);
  }, [dataState, dispatch]);

  function handleGroupCreation(group: FinxaiDataGroup) {
    dispatch(addGroup(group));
    dispatch(setModalOpen(false));
  }

   const getFilteredData = () => {
    let filteredData = dataState;
    
    // Apply exclusion filters
    filteredData = filterExcludedData(filteredData);

    // Apply existing filters
    if (currentTableIndex === 0) {
      const displayIdColumn = columnsState.find(
        (col) => col.name === "Instrument"
      );

      if (displayIdColumn) {
        filteredData = filteredData.filter(
          (row) => displayIdColumn.selector(row) !== "N.A"
        );
      }
    }

    if (
      groups[groupIndex].tickers.includes("alldata") ||
      currentTableIndex !== 0
    ) {
      return filteredData;
    }

    if (groups[groupIndex].tickers.length === 0) {
      return [];
    }

    return filteredData.filter((row) =>
      groups[groupIndex].tickers.includes(uid!)
    );
  };

  const handleSort = (index: number) => {
    /// Rendered data update
    dispatch(
      sortData({
        columnIndex: index,
        tableIndex: currentTableIndex,
        username: username,
      })
    );
    /// Actual data update
    const field = getSelectorField(columnsState[index].selector);
    logger.verbose("The field name to be sorted is: ", field);

    const sortDir = sortDirection;

    if (currentTableIndex == 0) {
      dispatch(sortTradeOrders({ field: field!, order: sortDir }));
    } else {
      // dispatch(sortMarketData({field: field!, order: sortDir }))
    }
  };

  const handleColumnReorder = (currentIndex: number, newIndex: number) => {
    const tableIndex = currentTableIndex;
    dispatch(swapColumns({ tableIndex, currentIndex, newIndex, username }));

    logger.debug("Columns are: ");
    logger.debug(columnsState.toLocaleString());
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    dispatch(setSearchInput(value));

    if (value.length >= 3) {
      const results = dataState.filter((item) =>
        item[uid!].toLowerCase().includes(value.toLowerCase())
      );
      dispatch(setSearchResults(results));
    } else {
      dispatch(setSearchResults([]));
    }
  };

  const handleTickerSelect = (ticker: string) => {
    setSearchTerm(ticker);
    dispatch(setSearchInput(ticker));
    dispatch(setSearchResults([]));
    dispatch(addTickerToGroup({ groupIndex, ticker }));
  };

  const applyConditionalStyles = (
    row: any,
    styles: Column["conditionalCellStyles"]
  ) => {
    const matchedStyle = styles?.find((style) => style.when(row));
    return matchedStyle ? matchedStyle.style : {};
  };

  const getSelectorField = (selector: Function) => {
    const match = selector.toString().match(/row\.(\w+)/);
    return match ? match[1] : null;
  };
  const ws = getWebSocketInstance();


  const renderQuantityCell = (orderQty: number, filledQty: number) => (
    <QuantityCell
      quantity={Number(orderQty)}
      filledQuantity={Number(filledQty)}
    />
  );

  const renderSideCell = (side: string) => (
    <div
      className={`
        inline-block px-2 py-1 rounded-full
        ${side === "BUY" ? "bg-green-600" : "bg-red-600"} 
        font-bold
      `}
      style={{
        margin: "auto",
        display: "inline-block",
      }}
    >
      {side}
    </div>
  );

  const renderStatusCell = (orderQty: number, filledQty: number) => {
    const status = _handleStatusBasedOnQuantity(orderQty, filledQty);
    return (
      <div
        className={`
          inline-block px-2 rounded-full
          ${status === "Filled" ? "bg-green-600 font-bold" : "bg-gray-500"}
        `}
        style={{
          margin: "auto",
          display: "inline-block",
        }}
      >
        {status}
      </div>
    );
  };



  // Modified render method to support both grouped and ungrouped views
  const renderTableBody = () => {
    if (tableGroups && enableGrouping) {
      return (
        <>
          {Object.entries(groupedData).map(([groupName, groupRows]) => (
            <React.Fragment key={groupName}>
              {groupRows.length > 0 && (
                <>
                  {/* Group Header */}
                  <tr 
                    className="bg-primary/10 cursor-pointer hover:bg-primary/20"
                    onClick={() => toggleGroup(groupName)}
                  >
                    <td 
                      colSpan={columnsState.length} 
                      className="p-2 border-gray-800 text-white font-medium"
                    >
                      <div className="flex items-center gap-2">
                        {expandedGroups.includes(groupName) ? 
                          <ChevronDown className="h-4 w-4" /> : 
                          <ChevronRight className="h-4 w-4" />
                        }
                        {groupName}
                        <span className="text-sm text-gray-400">
                          ({groupRows.length} items)
                        </span>
                      </div>
                    </td>
                  </tr>
                  {/* Group Content */}
                  {expandedGroups.includes(groupName) && groupRows.map((row, rowIndex) => (
                    renderTableRow(row, rowIndex)
                  ))}
                </>
              )}
            </React.Fragment>
          ))}
          {/* Ungrouped Data */}
          {ungroupedData.length > 0 && (
            <>
              <tr 
                className="bg-primary/10 cursor-pointer hover:bg-primary/20"
                onClick={() => toggleGroup('Ungrouped')}
              >
                <td 
                  colSpan={columnsState.length} 
                  className="p-2 border-gray-800 text-white font-medium"
                >
                  <div className="flex items-center gap-2">
                    {expandedGroups.includes('Ungrouped') ? 
                      <ChevronDown className="h-4 w-4" /> : 
                      <ChevronRight className="h-4 w-4" />
                    }
                    Ungrouped
                    <span className="text-sm text-gray-400">
                      ({ungroupedData.length} items)
                    </span>
                  </div>
                </td>
              </tr>
              {expandedGroups.includes('Ungrouped') && ungroupedData.map((row, rowIndex) => (
                renderTableRow(row, rowIndex)
              ))}
            </>
          )}
        </>
      );
    }

    // Default ungrouped view
    return getFilteredData().map((row, rowIndex) => renderTableRow(row, rowIndex));
  };

  const renderTableRow = (row: any, rowIndex: number) => (
    <tr
      key={ rowIndex}
      onClick={() => handleRowTap(row, rowIndex)}
      className={`border-collapse cursor-pointer ${
        rowIndex !== columnsState.length - 1 ? "border-b" : ""
      } ${
        onRowTap != null && selectedRow === rowIndex
          ? "bg-primary"
          : rowIndex % 2 === 0
          ? "bg-primaryTableBgLighter"
          : "bg-primaryTableBg"
      }`}
    >
      {columnsState.map((column, colIndex) => renderTableCell(row, column, colIndex))}
    </tr>
  );




  const renderTableCell = (row: any, column: Column, colIndex: number) => {
    const isMktData = isFinxaiMktData(row);
    const cellKey = isMktData ? row.security_id : row.order_id;
    const changedFields = isMktData ? changedMarketDataCells : changedTradeCells;

    if (currentTableIndex === 0) {
      if (column.name === "Filled/Size") {
        return (
          <td key={colIndex} className="border-gray-800 text-sm p-2 text-white flex items-center justify-center">
            {renderQuantityCell(row.order_qty, row.filled_qty)}
          </td>
        );
      }

      if (column.name === "Side") {
        return (
          <td key={colIndex} className="border-gray-800 text-sm text-white flex items-center justify-center">
            {renderSideCell(row.side)}
          </td>
        );
      }
    }

    return (
      <td
        key={colIndex}
        className={`border-gray-800 text-sm p-2 text-white ${
          colIndex !== columnsState.length - 1 ? "border-r" : ""
        } ${
          changedFields[cellKey]?.has(getSelectorField(column.selector)!)
            ? "animate-pulse bg-green-600"
            : ""
        }`}
        style={
          column.conditionalCellStyles
            ? applyConditionalStyles(row, column.conditionalCellStyles)
            : {}
        }
      >
        {column.name === "Order Status" 
          ? renderStatusCell(Number(row.order_qty), Number(row.filled_qty))
          : column.selector(row)
        }
      </td>
    );
  };
  
  
  return (
    <div className="datatable bg-primaryBg flex flex-col">
      <div className="flex flex-row rounded-t-2xl bg-tableHeaderBg text-gray-400">
        {enableGrouping &&
          groups.length > 1 &&
          groups.map((e, index) => (
            <button
              key={e.groupName + index}
              onClick={() => dispatch(setGroupIndex(index))}
              className={`${
                index == groupIndex
                  ? "shadow-2xl shadow-white bg-primary text-white font-bold"
                  : ""
              } rounded-t-2xl px-6 py-2 border-r border-gray-600`}
            >
              {`${groups[index].groupName}`}
            </button>
          ))}
        {groups[groupIndex].tickers.length > 1 &&
          groups[groupIndex].groupName !== "alldata" && (
            <TextField
              label={`Search by ${uid}`}
              value={searchTerm}
              onChange={handleSearchInputChange}
              variant="outlined"
              size="small"
              fullWidth
              sx={{ 
                margin: "0 16px", 
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "rgba(255,255,255,0.1)",
                  color: "white"
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(255,255,255,0.7)"
                }
              }}
            />
          )}
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center p-8 text-white text-lg">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-white mr-3"></div>
          Loading Data...
        </div>
      ) : (
        <table
          className={`w-full shadow-md border-b overflow-hidden ${
            groups.length == 1 ? "rounded-2xl" : ""
          }`}
        >
          <thead className="bg-tableHeaderBg text-white text-base rounded-2xl shadow-2xl">
            <tr>
              {columnsState.map((column, index) => (
                <th
                  className="text-start px-6 py-4 border-gray-700 hover:bg-primary/20 cursor-pointer"
                  key={index}
                  onClick={() => handleSort(index)}
                >
                  <div
                    key={column.name + index}
                    draggable
                    onDragStart={() => (currIndex.current = index)}
                    onDragEnter={() => (nIndex.current = index)}
                    onDragEnd={() =>
                      handleColumnReorder(currIndex.current, nIndex.current)
                    }
                    onDragOver={(e) => e.preventDefault()}
                    className="flex items-center justify-between"
                  >
                    <span>{column.name}</span>
                    {/* Optional sort indicator */}
                    {sortColIndex === index && (
                      <span className="ml-2 text-xs">
                        {sortDirection === "asc" ? "▲" : "▼"}
                      </span>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {renderTableBody()}
          </tbody>
        </table>
      )}
    </div>
  )};
function _handleStatusBasedOnQuantity(qty: number, filledQty: number): string {
  let status: string = "";

  switch (true) {
    case filledQty === 0:
      status = "Working";
      break;
    case filledQty > 0 && filledQty < qty:
      status = "Partially Filled";
      break;
    case filledQty === qty:
      status = "Filled";
      break;
    case filledQty > qty:
      status = "Overfilled";
      break;
    default:
      status = "Unknown";
  }

  return status;
}

export default CustomDataTable;
