import { TableGroups } from "../components/custom-data-table";

export function hasEmptyField(
  obj: Record<string, any>,
  bypassFields: string[] = []
): boolean {
  return Object.keys(obj).some((key) => {
    if (!bypassFields.includes(key)) {
      return false;
    }
    const value = obj[key];
    return value === null || value === undefined || value === "";
  });
}

export function jsonToMap(obj: any): Map<string, any> {
  const map = new Map<string, any>();
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      map.set(
        key,
        value instanceof Object && !(value instanceof Array)
          ? jsonToMap(value)
          : value
      );
    }
  }
  return map;
}

export function convertTableGroups(
  userPreferencesMap: string,
  tableName: string,
  columnName: string
): TableGroups {
  // Get the react_app data
  const rawJson = JSON.parse(userPreferencesMap)
  const convertedMap = new Map<string, any>(Object.entries(rawJson));
  const reactAppData = convertedMap.get("react_app");

  // If no table data exists, return empty object
  if (!reactAppData?.[tableName]) {
    return {};
  }

  const tableData = reactAppData[tableName];
  const tableGroups = tableData.groups;

  // Convert the groups array into the required TableGroups structure
  const convertedGroups: TableGroups = {};

  tableGroups?.forEach((groupObj: any) => {
    Object.entries(groupObj).forEach(([groupName, groupValues]) => {
      convertedGroups[groupName] = {
        [columnName]: groupValues as string[],
      };
    });
  });

  return convertedGroups;
}


// Takes in data from the converted table groups and returns unique instrument in a 
// array of string
export function extractUniqueElementsFromTableGroups(
  tableGroups: TableGroups
): string[] {
  const uniqueElements = new Set<string>();

  Object.values(tableGroups).forEach((group) => {
    Object.values(group).forEach((elementList) => {
      elementList.forEach((element) => {
        uniqueElements.add(element);
      });
    });
  });

  return Array.from(uniqueElements);
}