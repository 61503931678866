import { FinxaiTrade } from "../../models/finxaiTrade";
import { FinxaiMktData } from "../../models/finxaiMktData";
import { message_envelop, payload_type } from "../../models/protobuff-objects/finxai_msg_wrapper_pb";
import { user_login_request, user_login_response, user_logout_request, user_subscription_request } from "../../models/protobuff-objects/finxai_user_pb";
import { finxai_instrument, finxai_instrument_leg, finxai_mkt_data, finxai_strategy, finxai_trade, OrderStatus, Side } from "../../models/protobuff-objects/finxai_web_pb";
import { FinxAiUser } from "../../models/finxaiUser";
import { FinxaiStrategy } from "../../models/finxaiStrategy";
import { FinxaiInstrument } from "../../models/finxaiInstrument";
import { FinxaiInstrumentLeg } from "../../models/finxaiInstrumentLeg";
import logger from "../../utils/logger";

export class DataGridWebSocketService {

  buildLoginRequest(username: string, password: string, isQa: boolean) {
    try {
      const ul = new user_login_request();
      ul.setUserId(username);
      ul.setPasswd(password);
      ul.setEnv(isQa ? "QA" : "");

      const r = new message_envelop();
      r.setMessageType(payload_type.LOGIN_REQ);
      r.setPayload(ul.serializeBinary());

      logger.debug("Login request sent to the server");

      return r;
    } catch (error) {
      console.error("Error sending login request:", error);
      throw error;
    }
  }

  buildLogoutRequest(user: FinxAiUser) {
    try {
      const ul = new user_logout_request;
      ul.setSessionId(user.sessionId);
      ul.setUserId(user.userId);
    
      const r = new message_envelop();
      r.setMessageType(payload_type.LOGOUT_REQ);
      r.setPayload(ul.serializeBinary());
      logger.debug("Logout request created");
      return r;
    } catch (error) {
      console.error("Error sending logout request:", error);
      throw error;
    }
  }

  

  buildSubscriptionRequest(user: FinxAiUser) {
    try {
      const ul = new user_subscription_request();
      ul.setSessionId(user.sessionId);
      ul.setUserId(user.userId);

      const r = new message_envelop();
      r.setMessageType(payload_type.SUBSCRIPTION_REQUEST);
      r.setPayload(ul.serializeBinary());
      logger.debug("Subscription request created");
      return r;
    } catch (error) {
      console.error("Error sending subscription request:", error);
      throw error;
    }
  }

  public builUserObject(loginResponse: user_login_response, username: string, password: string) : FinxAiUser
  {
    return {
      username: username,
      password: password,
      sessionId: loginResponse.getSessionId(),
      userId: loginResponse.getUserId(),
      userPreferences:  loginResponse.getUserPreferences()
    } as FinxAiUser;
  }

  async handleBinaryMessage(data: Blob,): Promise<user_login_response | finxai_trade | finxai_mkt_data | finxai_strategy | null> {
    try {
      const buffer = await data.arrayBuffer();
      const uint8Array = new Uint8Array(buffer);
  
      const envelope = message_envelop.deserializeBinary(uint8Array);
      const messageType = envelope.getMessageType();
      const payload = envelope.getPayload_asU8();
  
      switch (messageType) {
        case payload_type.LOGIN_RESPONSE:
          logger.debug("Received payload type LOGIN_RESPONSE: SERVICE");
          const loginResponse = user_login_response.deserializeBinary(payload);
          return loginResponse;
  
        case payload_type.FINXAI_TREADE:
          logger.debug("Payload type is FinxAiTrade: SERVICE");
          const tradeResponse = finxai_trade.deserializeBinary(payload);
          return tradeResponse;

        case payload_type.FINXAI_MKTDATA:
          logger.debug("Payload type is FinxAiMarketData: SERVICE");
          const marketDataResponse = finxai_mkt_data.deserializeBinary(payload);
          return marketDataResponse;

        case payload_type.FINXAI_STRATEGY: 
        logger.debug("Payload type is  FinxAiStrategy: SERVICE");

        const strategyResponse = finxai_strategy.deserializeBinary(payload);
        return strategyResponse;
  
        default:
          return null;
      }
    } catch (error) {
      console.error("Error processing binary message:", error);
      return null;
    }
  }



  public buildObject(
    object: finxai_trade | finxai_mkt_data | finxai_instrument | finxai_strategy | finxai_instrument_leg[],
    marketData: FinxaiMktData[],
    strategyData: FinxaiStrategy[]
  ): FinxaiTrade | FinxaiMktData | FinxaiStrategy | FinxaiInstrument | FinxaiInstrumentLeg[] | undefined {
  
    // Handle the case where object is an array of finxai_instrument_leg
    if (Array.isArray(object)) {
      return object.map(leg => ({
        leg_id: leg.getLegId(),
        security_id: leg.getSecurityId(),
        ticker_symbol: leg.getTickerSymbol(),
        bbg_ticker: leg.getBbgTicker(),
        price_ratio: leg.getPriceRatio(),
        size_ratio: leg.getSizeRatio(),
      } as FinxaiInstrumentLeg));
    }
  
    // Look up market data based on security_id
    const correspondingMarketData = marketData.find(mkt => mkt.security_id === object.getSecurityId());
    const correspondingStrategyTickerFind = strategyData.find(strategy => strategy.instrument.security_id === object.getSecurityId())
    if (object instanceof finxai_trade) {
      logger.debug("Order status is: ", object.getOrderStatus())
      return {
        order_id: object.getOrderId(),
        parent_ord_id: object.getParentOrdId(),
        security_id: object.getSecurityId(),
        ticker_symbol: object.getTickerSymbol(), /// This is handled in dataSlice.ts
        price: Number(object.getPrice().toFixed(3)),
        avg_fill_px: object.getAvgFillPx(),
        order_qty: object.getOrderQty(),
        filled_qty: object.getFilledQty(),
        // orderStatus: object.getOrdStatus().toString(),
        account: object.getAccount(),
        side: object.getSide() === Side.BUY ? "BUY" : "SELL",
        bid_px: "N/A",
        offer_px: "N/A",
        orderStatus: object.getOrderStatus()
      } as FinxaiTrade;
    } else if (object instanceof finxai_mkt_data) {
      return {
        security_id: object.getSecurityId(),
        bid_px: Number(object.getBidPx().toFixed(3)),
        bid_sz: object.getBidSz(),
        offer_px: Number(object.getOfferPx().toFixed(3)),
        offer_sz: object.getOfferSz(),
        last_traded_px: object.getLastTradedPx(),
        last_traded_sz: object.getLastTradedSz(),
      } as FinxaiMktData;
    } else if (object instanceof finxai_strategy) {
      logger.debug("Strategy object detected")
      return this.buildStrategyObject(object, marketData);
    } else {
      logger.debug("Unsupported object type");
    }
  }

  /// Strategy Handling
  private buildStrategyObject(
    strategyProto: finxai_strategy,
    marketData: FinxaiMktData[]
  ): FinxaiStrategy {
    const instrument = this.buildInstrumentObject(strategyProto.getInstrument()!);
    const instLegs = strategyProto.getInstLegsList().map(leg => this.buildInstrumentLegObject(leg));
  
    return {
      instrument: instrument,
      inst_legs: instLegs
    };
  }
  
  private buildInstrumentObject(instrumentProto: finxai_instrument): FinxaiInstrument {
    return {
      leg_id: instrumentProto.getLegId(),
      security_id: instrumentProto.getSecurityId(),
      ticker_symbol: instrumentProto.getTickerSymbol(),
      bbg_ticker: instrumentProto.getBbgTicker()
    };
  }
  
  private buildInstrumentLegObject(legProto: finxai_instrument_leg): FinxaiInstrumentLeg {
    return {
      leg_id: legProto.getLegId(),
      security_id: legProto.getSecurityId(),
      ticker_symbol: legProto.getTickerSymbol(),
      bbg_ticker: legProto.getBbgTicker(),
      price_ratio: legProto.getPriceRatio(),
      size_ratio: legProto.getSizeRatio()
    };
  }

   public getOrderStatusString(status: OrderStatus): string {
    switch (status) {
        case OrderStatus.ORD_STATUS_UNSPECIFIED:
            return 'Unspecified';
        case OrderStatus.ORD_STATUS_CANCELED:
            return 'Cancelled';
        case OrderStatus.ORD_STATUS_DONE_FOR_DAY:
            return 'Done for Day';
        case OrderStatus.ORD_STATUS_FILLED:
            return 'Filled';
        case OrderStatus.ORD_STATUS_NEW:
            return 'New';
        case OrderStatus.ORD_STATUS_PARTIALLY_FILLED:
            return 'Partially Filled';
        case OrderStatus.ORD_STATUS_PENDING_CANCEL:
            return 'Pending Cancel';
        case OrderStatus.ORD_STATUS_REJECTED:
            return 'Rejected';
        case OrderStatus.ORD_STATUS_STOPPED:
            return 'Stopped';
        case OrderStatus.ORD_STATUS_PENDING_NEW:
            return 'Pending New';
        case OrderStatus.ORD_STATUS_SUSPENDED:
            return 'Suspended';
        case OrderStatus.ORD_STATUS_CALCULATED:
            return 'Calculated';
        case OrderStatus.ORD_STATUS_EXPIRED:
            return 'Expired';
        case OrderStatus.ORD_STATUS_ACCEPTED_FOR_BIDDING:
            return 'Accepted for Bidding';
        case OrderStatus.ORD_STATUS_PENDING_REPLACE:
            return 'Pending Replace';
        case OrderStatus.ORD_STATUS_REPLACED:
            return 'Replaced';
        default:
            return 'Unknown Status';
    }
}
  
}
